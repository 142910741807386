<template>
  <!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
  <div id="Newrecruitment" style="width:100vw">
    <div id="a5d69c7b">
      <div id="af70402c">
        <div id="a6c8e2a0">岗位名称</div>
        <el-input id="d1b3530b" v-model="inp_d1b3530b"> </el-input>
      </div>
      <div id="a744ba00">
        <div id="ab63e1f2">工作地点</div>
        <el-input id="aecfbe19" v-model="inp_aecfbe19"> </el-input>
        <!-- <el-button id="df7b5da1" @click="clickdf7b5da1()">
        选择地点
      </el-button> -->
      </div>
      <div id="c663034a">
        <div id="a99266d9">是否置顶</div>
        <el-radio-group id="ab671e51" v-model="rdg_ab671e51">
          <el-radio id="a257d4e9" label="1"> 是 </el-radio>
          <el-radio id="a690d846" label="0"> 否 </el-radio>
        </el-radio-group>
      </div>
      <div id="aaa6f313">
        <div id="bd5e64dd">状态</div>
        <el-select
          id="fb14f4f6"
          placeholder="请选择状态"
          v-model="val_fb14f4f6"
        >
          <el-option
            v-for="item in op_fb14f4f6"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div id="fd313597">
        <div id="a8da2044">薪酬</div>
        <el-input id="ea7e4032" v-model="inp_ea7e4032"> </el-input>
      </div>
      <div id="a412ce0b">
        <div id="ab47ed13">职位要求</div>
        <!-- <div id="a4a33710" ref="ref_a4a33710">
      </div> -->
        <el-form>
          <el-form-item>
            <quill-editor
              v-model="content"
              ref="myQuillEditor"
              @ready="ready($event)"
              :options="editorOption"
              @change="onEditorChange($event)"
              class="ql-editor"
            >
            </quill-editor>
          </el-form-item>
        </el-form>
      </div>
      <div id="a80ef31d">
        <el-button id="a5918b8e" @click="clicka5918b8e()"> 保存 </el-button>
        <el-button id="a8b1c3e6" @click="clicka8b1c3e6()"> 取消 </el-button>
      </div>
    </div>
    <div
      id="dlgfabb74601"
      v-show="vis_abb74601"
      @click.self="vis_abb74601 = false"
    >
      <div id="abb74601">
        <div id="a9856761">工作地点</div>
        <div class="map">
          <el-input
            v-model="addressKeyword"
            placeholder="请输入地址来直接查找相关位置"
            style="width: 80%"
          ></el-input>
          <baidu-map
            class="bmView"
            :scroll-wheel-zoom="true"
            :center="center"
            :zoom="zoom"
            ak="BO5EzBQehA6qp5vhF3GDiaGWXR4XRdQA"
            @click="getClickInfo"
          >
            <bm-view style="width: 100%; height: 280px; flex: 1"></bm-view>
            <bm-scale anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-scale>
            <bm-geolocation
              anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
              :showAddressBar="true"
              :autoLocation="true"
            ></bm-geolocation>
            <!-- <bm-marker :position="postionMap"></bm-marker> -->
            <bm-local-search
              :keyword="addressKeyword"
              :auto-viewport="true"
              style="width: 80%; height: 80px; flex: 1"
              :pageCapacity="2"
            ></bm-local-search>
          </baidu-map>
        </div>
        <div id="d753c014">
          <el-button id="aceded2c" @click="clickaceded2c()"> 确认 </el-button>
          <el-button id="ff11faf0" @click="clickff11faf0()"> 取消 </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
import {
DALINEWEB_ARTICLECENTER_V0X0X1_addPosition,
DALINEWEB_ARTICLECENTER_V0X0X1_getPosition,
DALINEWEB_ARTICLECENTER_V0X0X1_updatePosition,
} from '../api/dalapi.js'
//在此注释下方添加自定义函数
var fonts = ['SimSun', 'SimHei','Microsoft-YaHei','KaiTi','FangSong','Arial','Times-New-Roman','sans-serif'];
var Font = Quill.import('formats/font');
let fontSizeStyle = Quill.import('attributors/style/size')
Font.whitelist = fonts; //将字体加入到白名单
fontSizeStyle.whitelist = ["18px","20px","22px","24px","26px","28px","30px","32px","36px","40px"]
Quill.register(Font, true);
Quill.register(fontSizeStyle, true);
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {lineHeightStyle}  from '../quill/lineHeight'
import Quill from "quill";
//此注释下方为自动引用部分，请不要修改
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
  [{ header: 1 }, { header: 2 }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
  [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
  [{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
  [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
  // [{ size: ["small", false, "large", "huge"] }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
  [{ color: [] }, { background: [] },{ size: fontSizeStyle.whitelist }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
  [{ lineheight: ["1", "1.5", "1.75", "2", "3", "4", "5"] }], // 对齐方式
   [{ 'font': fonts }],       //把上面定义的字体数组放进来
  ["clean"], // 清除文本格式-----['clean']
];
import {BaiduMap,BmScale} from 'vue-baidu-map'
import BmView from 'vue-baidu-map/components/map/MapView.vue'
import BmGeolocation from 'vue-baidu-map/components/controls/Geolocation.vue'; //定位
import BmLocalSearch from 'vue-baidu-map/components/search/LocalSearch.vue'
export default{
    components: {
      BaiduMap,
      BmView,
      BmLocalSearch,
      BmGeolocation,
      BmScale,
      quillEditor
  },
  data(){
    return{
    inp_d1b3530b:'',
    rdg_ab671e51:'',
    rd_a257d4e9:'',
    rd_a690d846:'',
    op_fb14f4f6:[  {value: 0,label: '使用'},
        {value: 2,label: '停用'}],
    val_fb14f4f6:'',
    inp_ea7e4032:'',
    ref_a4a33710:'',
    vis_abb74601:false,
    inp_aecfbe19:'',
    inp_a2f5b946:'',
    op_a0a1de75:[],
    val_a0a1de75:'',
      //在此注释下方添加自定义变量
      edt_ref_a4a33710:'',
      content:'',
    editorOption: { placeholder: '', theme: 'snow',
            modules: {
                //工具栏定义的
            toolbar:{container:toolbarOptions,
                              handlers: {
                              lineheight: (value) => {
                                if (value) {
                                  let quill = this.$refs.myQuillEditor.quill;
                                  quill.format("lineHeight", value);
                                }
                                },
                                },
                              },

            } },
      PositionID: this.$route.query.dal_id,
      addressKeyword:'',
      center:{
        lng: 114.411172,

        lat: 30.482898
      },
      zoom:18,

    }
  },
  mounted(){
    // edt_ref_a4a33710 = new dal_editor(this.$refs.ref_a4a33710)
    // edt_ref_a4a33710.config.uploadImgMaxLength = 1
    // edt_ref_a4a33710.config.zIndex = 1
    // edt_ref_a4a33710.config.height = 118
    // edt_ref_a4a33710.config.customUploadImg = (resultFiles, insertImgFn) => {
    //   let fd = new FormData();
    //   fd.append("file", resultFiles[0]);
    //   dal_rest.post("", fd, {
    //     headers: { "Content-Type": "multipart/form-data","keepAlive":true },
    //     baseURL: "/myupload",
    //   })
    //   .then(
    //     (res) => {
    //       console.log("上传成功", res);
    //       let imgurl = res.content.url;
    //       insertImgFn(imgurl)
    //     },
    //     (err) => {
    //       console.log("上传失败", err);
    //       this.$message("上传失败");
    //     })
    // }
    // edt_ref_a4a33710.create()
    // edt_ref_a4a33710.txt.html(this.$route.query.requirements)
    // this.edt_ref_a4a33710 = edt_ref_a4a33710
    this.getPositondata()
  },
  computed:{
         editor() {
      return this.$refs.myQuillEditor.quill;
    }
  },
  watch:{
  },
  methods:{
    // handler({BMap,map}){
    //   console.log(BMap,map);
    //   this.center.lng = 114.411199,
    //   this.center.lng = 30.482871
    // this.zoom = 18
    // },
        onEditorChange({html}) {
       console.log("a")
       this.content = html},
    ready() {
      Quill.register({ "formats/lineHeight": lineHeightStyle }, true);
    },
        getClickInfo(e) {
      console.log(e.point.lng);
      console.log(e.point.lat);
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
    },
    // async getLocationPoint(e){
    //   this.lng = e.point.lng;
    //   this.lat = e.point.lat;
    //   let geoCoder = new BMap.Geocoder();
    //   geoCoder.getPoint(this.addressKeyword, point => {
    //     this.selectedLng = point.lng;
    //     this.selectedLat = point.lat;
    //   });
    //   geoCoder.getLocation(e.point,res=>{
    //     console.log(res);
    //   })

    // },
    async clickdf7b5da1(){
      this.vis_abb74601 = true
    },
    async clicka5918b8e(){
      if (this.PositionID===undefined) {
         if (this.inp_d1b3530b==="") {
        this.$message("请输入职位名称")
        return
      }
      let dicinput = {
        PositionName:this.inp_d1b3530b,
        location:this.inp_aecfbe19,
        top:this.rdg_ab671e51,
        status:this.val_fb14f4f6 == "使用"? 0 : 2,
        salary:this.inp_ea7e4032,
        requirements:this.content
      }
      if (this.rdg_ab671e51==="") {
        dicinput.top = 0
      }
      console.log(dicinput.requirements,"guo");
      const res =await DALINEWEB_ARTICLECENTER_V0X0X1_addPosition(dicinput)
      const res1 = res.cContent
      if (res1.resCode ===0) {
          this.$message("添加成功")
          this.$router.push('recruitment')
        }
      }
      else{
        let dicinput = {
           PositionID:this.PositionID,
        PositionName:this.inp_d1b3530b,
        location:this.inp_aecfbe19,
        top:this.rdg_ab671e51,
        status:this.val_fb14f4f6,
        salary:this.inp_ea7e4032,
        requirements:this.content
      }
      if (this.val_fb14f4f6 =="使用" ) {
        dicinput.status = 0
      }
        console.log(dicinput);
        const res =await DALINEWEB_ARTICLECENTER_V0X0X1_updatePosition(dicinput)
        const res1 = res.content
        if (res1.resCode ===0) {
          this.$message("修改成功")
          this.$router.push('recruitment')
        }
      }

    },
    async clicka8b1c3e6(){
      this.$router.push('recruitment')
    },
    async clickaceded2c(){
    },
    async clickff11faf0(){
    },
    //在此注释下方添加自定义函数或事件
    async getPositondata(){
      if (this.PositionID != undefined) {
      let dicinput = {
        PositionID:this.PositionID
      }
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getPosition(dicinput)
      const positioninfo =res.content

      if (positioninfo.resCode ===0) {
        let positiondict = positioninfo.resContent[0]
        //  console.log(positiondict);
        this.inp_d1b3530b = positiondict.PositionName
        this.inp_aecfbe19 = positiondict.location
        this.rdg_ab671e51 = (positiondict.top).toString()
        this.val_fb14f4f6 = positiondict.status === 0 ?"使用":"停用"
        this.inp_ea7e4032 = positiondict.salary
        this.content = positiondict.requirements
      }
      }
      },



  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#a5d69c7b {
  width: 1649px;
  position: relative;
  padding-bottom: 348px;
}
#af70402c {
  width: 588px;
  position: relative;
  padding-left: 70px;
  padding-top: 216px;
}
#a6c8e2a0 {
  padding-top: 13px;
  padding-bottom: 13px;
  display: inline-block;
  vertical-align: middle;
}
#a6c8e2a0 {
  text-align: left;
  color: #333;
  font-size: 13px;
  font-family: PingFang SC;
}
#af70402c .el-input {
  width: 436px;
  margin-left: 100px;
  display: inline-block;
  vertical-align: middle;
}
#a744ba00 {
  width: 294px;
  position: relative;
  margin-top: 43px;
  padding-left: 70px;
}
#ab63e1f2 {
  padding-top: 6px;
  padding-bottom: 6px;
  display: inline-block;
  vertical-align: middle;
}
#ab63e1f2 {
  text-align: left;
  color: #333;
  font-size: 13px;
  font-family: PingFang SC;
}
#df7b5da1 {
  width: 142px;
  margin-left: 100px;
  display: inline-block;
  vertical-align: middle;
}
#df7b5da1 {
  background-color: rgb(20, 22, 32, 1);
  border-radius: 0px 0px 0px 0px;
}
#df7b5da1 {
  color: #fff;
  font-size: 14px;
  font-family: PingFang SC;
  border: transparent;
}
#c663034a {
  width: 328px;
  position: relative;
  margin-top: 43px;
  padding-left: 71px;
  display: flex;
}
#a99266d9 {
  display: inline-block;
  vertical-align: middle;
  width: 70px;
}
#a99266d9 {
  text-align: left;
  color: #333;
  font-size: 13px;
  font-family: PingFang SC;
}
#ab671e51 {
  /* width:177px; */
  margin-left: 50px;
  display: inline-block;
  vertical-align: middle;
}
#ab671e51 {
  /* width:auto; */
  display: inline-block;
  vertical-align: middle;
}
#a257d4e9 {
  width: 41px;
  display: inline-block;
  vertical-align: middle;
}
#a690d846 {
  width: 41px;
  margin-left: 95px;
  display: inline-block;
  vertical-align: middle;
}
#aaa6f313 {
  width: 588px;
  position: relative;
  margin-top: 43px;
  padding-left: 70px;
}
#bd5e64dd {
  padding-top: 13px;
  padding-bottom: 13px;
  display: inline-block;
  vertical-align: middle;
}
#bd5e64dd {
  text-align: left;
  color: #333;
  font-size: 13px;
  font-family: PingFang SC;
}
#aaa6f313 .el-select {
  width: 436px;
  margin-left: 126px;
}
#fd313597 {
  width: 588px;
  position: relative;
  margin-top: 30px;
  padding-left: 70px;
}
#a8da2044 {
  padding-top: 13px;
  padding-bottom: 13px;
  display: inline-block;
  vertical-align: middle;
}
#a8da2044 {
  text-align: left;
  color: #333;
  font-size: 13px;
  font-family: PingFang SC;
}
#fd313597 .el-input {
  width: 436px;
  margin-left: 126px;
  display: inline-block;
  vertical-align: middle;
}
#a412ce0b {
  width: 952px;
  position: relative;
  margin-top: 43px;
  padding-left: 70px;
}
#ab47ed13 {
  padding-bottom: 182px;
  display: inline-block;
  vertical-align: middle;
}
#ab47ed13 {
  text-align: left;
  color: #333;
  font-size: 13px;
  font-family: PingFang SC;
}
#a4a33710 {
  width: 800px;
  margin-left: 100px;
  display: inline-block;
  vertical-align: middle;
}
#a80ef31d {
  width: 344px;
  position: relative;
  margin-top: 60px;
  padding-left: 70px;
}
#a5918b8e {
  width: 142px;
  display: inline-block;
  vertical-align: middle;
}
#a5918b8e {
  background-color: rgb(20, 22, 32, 1);
  border-radius: 0px 0px 0px 0px;
}
#a5918b8e {
  color: #fff;
  font-size: 14px;
  font-family: PingFang SC;
  border: transparent;
}
#a8b1c3e6 {
  width: 142px;
  margin-left: 60px;
  display: inline-block;
  vertical-align: middle;
}
#a8b1c3e6 {
  background-color: rgb(153, 153, 153, 1);
  border-radius: 0px 0px 0px 0px;
}
#a8b1c3e6 {
  color: #fff;
  font-size: 14px;
  font-family: PingFang SC;
  border: transparent;
}
#dlgfabb74601 {
  position: fixed;
  z-index: 100;
  top: -170px;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
#abb74601 {
  position: absolute;
  top: 248px;
  left: 610px;
  width: 700px;
  height: 613px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
}
#a9856761 {
  padding-top: 30px;
}
#a9856761 {
  text-align: center;
  color: #333;
  font-size: 24px;
  font-family: PingFang SC;
}
#a900b2a4 {
  width: 545px;
  position: relative;
  margin-top: 30px;
  padding-left: 70px;
}
#ab71c826 {
  padding-top: 13px;
  padding-bottom: 13px;
  display: inline-block;
  vertical-align: middle;
}
#ab71c826 {
  text-align: right;
  color: #333;
  font-size: 13px;
  font-family: PingFang SC;
}
#a900b2a4 .el-input {
  width: 443px;
  margin-left: 30px;
  display: inline-block;
  vertical-align: middle;
}
#a696ff93 {
  width: 512px;
  position: relative;
  margin-top: 30px;
  padding-left: 103px;
}
#adfc91bd {
  padding-top: 13px;
  padding-bottom: 13px;
  display: inline-block;
  vertical-align: middle;
}
#adfc91bd {
  text-align: right;
  color: #333;
  font-size: 13px;
  font-family: PingFang SC;
}
#a696ff93 .el-input {
  width: 443px;
  margin-left: 30px;
  display: inline-block;
  vertical-align: middle;
}
#a1b3341f {
  width: 499px;
  position: relative;
  margin-top: 30px;
  padding-left: 116px;
}
#f0125b4f {
  padding-top: 13px;
  padding-bottom: 13px;
  display: inline-block;
  vertical-align: middle;
}
#f0125b4f {
  text-align: left;
  color: #333;
  font-size: 13px;
  font-family: PingFang SC;
}
#a1b3341f .el-select {
  width: 443px;
  margin-left: 30px;
}
#ad083672 {
  width: 620px;
  height: 188px;
  margin-top: 30px;
  padding-left: 40px;
}
#ad083672 {
  vertical-align: middle;
}
#d753c014 {
  width: 404px;
  position: relative;
  margin-top: 180px;
  padding-left: 148px;
  padding-bottom: 40px;
}
#aceded2c {
  width: 142px;
  display: inline-block;
  vertical-align: middle;
}
#aceded2c {
  background-color: rgb(20, 22, 32, 1);
  border-radius: 0px 0px 0px 0px;
}
#aceded2c {
  color: #fff;
  font-size: 14px;
  font-family: PingFang SC;
  border: transparent;
}
#ff11faf0 {
  width: 142px;
  margin-left: 120px;
  display: inline-block;
  vertical-align: middle;
}
#ff11faf0 {
  background-color: rgb(153, 153, 153, 1);
  border-radius: 0px 0px 0px 0px;
}
#ff11faf0 {
  color: #fff;
  font-size: 14px;
  font-family: PingFang SC;
  border: transparent;
}
/*在此注释下方添加自定义样式*/
#a744ba00 .el-input {
  width: 436px;
  margin-left: 150px;
  display: inline-block;
  vertical-align: middle;
}
.bmView {
  height: 300px;
}
.editor {
  line-height: normal !important;
  height: 500px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="26px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: "26px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="30px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
  content: "30px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: "32px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: "36px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="40px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="40px"]::before {
  content: "40px";
}


.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}

</style>
